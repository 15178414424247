import React from "react";

import AuditoriumComp from "../components/general/Auditorium";

function Auditorium({ history }) {
  return (
    <>
      <AuditoriumComp history={history} />
    </>
  );
}

export default Auditorium;
