import React from "react";

import LobbyComp from "../components/general/Lobby";

function Lobby({ history }) {
  return (
    <>
      <LobbyComp history={history} />
    </>
  );
}

export default Lobby;
